.tracking-consent-banner {
  font-weight: bold;
  display: flex;
  background: rgba(0, 0, 0, 1);
  color: #FFF;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 9999;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  padding: 1em 0;
  &__btn-container {
    text-align: center;
    .btn {
      min-width: inherit;
    }
  }

  .consent-message {
    margin: 10px;
    padding: 20px;
    text-align: center;
    line-height: 20px;
    a {
      color: #FFF;
    }
  }

  .consent-btn {
    border: none;
    padding: 5px 20px;
    color: #ad2a33;
    background: white;
    text-transform: uppercase;
    min-width: auto;
    margin: 5px 10px;
    border-radius: 0;
  }

  .close-btn {
    border: none;
    background: none;
    font-weight: normal;
    font-size: 2em;
    position: absolute;
    top: 0;
    right: 25px;
    color:white;
  }


  .hidden{
    display:none;
    visibility:hidden;
  }
}
